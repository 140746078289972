#idWatermark{
  //1. responsive
  @include make-row();
  @include make-r-col(12);
  display: flex;
  justify-content: center;

  //2. set position and size
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 999999;

  #idWatermarkText{
    position:absolute;
    top: 190px;
    width:450px;
    height:300px;
    p{
      color: $tmpltsh_font-color-watermark;
      font-size: 6em;
      font-weight: bold;
      pointer-events: none;
      -webkit-transform: rotate(-30deg);
      -moz-transform: rotate(-30deg);
    }
  }
}
