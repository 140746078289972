.LayoutDefault{
	>.row>section.content{
		@include media-breakpoint-down(md) {
			order: -1;	
		}
		
		@include make-r-col(12, 12, 12, 9);
		margin-bottom: $spacer * 2;	
	}

	//Font Resize
 	&.fs-plus-1{
 		font-size: 1.6rem;
 		
 		h4{
			font-size: 1.6rem;
 		}
		input, select, textarea{
			font-size: 1.6rem !important;
		}
 	}
 	&.fs-plus-2{
 		font-size: 1.7rem;

 		h4{
			font-size: 1.7rem;
 		}
		input, select, textarea{
			font-size: 1.7rem !important;
		}

 	}
 	&.fs-plus-3{
 		font-size: 1.8rem;

 		h4{
			font-size: 1.8rem;
 		}
		input, select, textarea{
			font-size: 1.8rem !important;
		}

 	}	

}