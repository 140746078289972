.ViewDatenschutz{

	background-color: #fff;
	padding: 0 -2rem;

	.container{
		@include pp($spacer*2, $spacer*4);
	}

	a:link, a:visited{
		color: $tmpltt_font-color-highlight;
	}

}
