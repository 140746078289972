//fixes a problem in Firefox
//FF is not able to print a "display: flex" area, that spans over multiple pages

@media print{
	#wrapper{
		display: block !important;
	}

	.SearchDetails .SearchResultsWithMap{
		display: block !important;
		.angebot{
			display: block !important;
		}
	}

	.SearchDetails{
			width: 100%;
			padding-top: 200px;
	}

	//Suchergebnisse
	.herorow .herocol {height: 0px !important;}
	.herorow .herocol .hero-img {display: none;}
	.SearchFormEnhanced {display: none;}
	.MapResult {display: none;}
	.searchresults-header{
		position: none !important;
		margin-top: 170px;
		margin-left: -150px;
		.backBtn{display: none;}
		.resetBtn{display: none;}
	}
	.result-sets{
		flex: 0 0 100% !important;
		max-width: 100% !important;
		.eventmorebar{
			display: none;
		}
	}
	.card-accessibilty-icons svg path{
		fill: $tmpltt_font-color-results;
	}




}
