// siehe SearchDetails.scss
.SearchOffersFromProvider{
  //@include debug-layout;
		//@include make-row();
    //@include my($spacer);
  background-color: $tmpltt_layout_color-general;
  // padding-top:
  @include py($spacer*2);
	//top, right; bottom; left,
  @include mm(0, -$spacer*3, -$spacer*3, -$spacer*3);

	.createresults-header{
		background-color: $tmpltt_font-color-results;
    @include mx($spacer);
		//margin-bottom: $spacer;
		.title{
			color: $tmpltt_font-color-results_contrast;
      @include pp($spacer);
		}
	}

  .result-sets-row{
    @include make-row();
		@include py(3);
    @include mx(1);

		.ResultSet{
			@include make-r-col(12,12,6,6,6);
		}
  }

  .ResultSet{
    display: flex;
  	@include mb($grid-gutter-width);
    background-color: $tmpltt_layout_color-general;
    box-shadow: none;

    .card-content-wrapper{
      background-color: $tmpltt_layout_color-partial;
    }
  }

}
