.TemplateMain{
//@include debug-layout;

	.herorow{
		@include make-row();
		background: transparent;
		margin-top: -10px;

		.titlecol{
			@include make-r-col(12,12,12,3,3);

			h1{
				margin-top: 20px;
				font-size: 2.8rem;
				line-height: 4.2rem;

				@include media-breakpoint-up(sm) {
					margin-top: 50px;
					font-size: 3rem;
					line-height: 4.5rem;
				}
				@include media-breakpoint-up(md) {
					margin-top: 50px;
					font-size: 3.3rem;
					line-height: 5rem;
				}
			}
			h2{
				@include media-breakpoint-up(lg) {
					margin-top: 40px;
				}
				margin-bottom: $spacer*2;

				color: $tmpltt_font-color-content-h2-title;

				font-weight: 200;
				font-size: 2.4rem;
				line-height: 3.6rem;
				@include media-breakpoint-up(sm) {
					font-size: 2.6rem;
					line-height: 3.9rem;
				}
				// @include media-breakpoint-up(md) {
				// 	font-size: 3rem;
				// 	line-height: 4.5rem;
				// }
			}
		}

		.herocol{
			height:400px;
			@include media-breakpoint-up(md) {
				height:600px;
			}
			@include make-r-col(12,12,12,9,9);

			.hero-img{
				object-fit: cover;
      	height: 100%;
      	width: 100%;
				@include media-breakpoint-only(lg) {
					width: calc(100% + 20px);
				}
				@include media-breakpoint-only(xl) {
					width: calc(100% + 30px);
				}
			}

			@extend .align-self-center;
			// position: relative;
			// left: 9px;

			>.title{ //ToDo scss: wird es gebraucht?
				background: #fff;
				color: $tmpltt_font-color-content;
				width: 80%;

				position: absolute;
				bottom: 100px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}


	.site-header{
		// ? background: $tmpltt_layout_color-header;

		.site-opening{
				@include make-r-col(11,11,8);

				//height:100px;
				.logocol {
					//@include make-r-col(2,3);
					// .site-logo{
					// 	@include make-row();
					// 	width:100%
					// }
					@extend .d-flex;
					.logo{
						@include mm(10px);
						//@include make-col(12);

						a{
							position: relative;
							display: block;
							width: 250px;
							@include media-breakpoint-down(lg) {
								width: 220px;
							}

							padding-left: 90px;
							@include media-breakpoint-only(xs) {
								padding-left: 50px;
							}

							cursor: pointer;

							h1{
								color: $tmpltt_font-color-content;
								font-weight: 900;

								font-size: 35px;
								line-height: 35px;
								position: relative;
								top: 24px;

								@include media-breakpoint-only(xs) {
									font-size: 26px;
									line-height: 26px;
								}



								small{
									font-weight: 500;
								}
							}
						}
					}
				}
			}

		//Extra Function Menu (Drucken, teilen usw.)
		.site-menus{
			@include make-r-col(1,1,4);
			@include pp(0,2);
			.desktopfunctionscol{
				display: flex;
				@include media-breakpoint-down(sm) {
					display: none;
				}
				font-size: 1.3rem;



				white-space: nowrap;
				color: $tmpltt_font-color-searchmask;
				button{
					height: 16px;
					width: 16px;
					background: transparent;
					color: $tmpltt_font-color-menufunctions;
					font-size: 10px !important;
					line-height: 12px !important;
					padding: 0;
					margin: $spacer 0.2*$spacer;
					border:0;
				}
				//hide backend login //ToDo: wollen wir das überhaupt?
				.fa-lock{
					display: none;
				}
				.dropdown-menu{
					border: none;
					background: transparent;
					margin-top: -$spacer;
					button{
						margin-right: 2*$spacer;
					}
				}
				svg{
					width:28px;
					margin-right:1rem;
					filter:grayscale(1); //ToDo scss: ist es eine Variable?
				}
				svg:hover,svg:active{
					filter:none;
				}
			}
			.mainmenucol{
				.navbar-toggler {
					margin-top: 3rem;
					color: $tmpltt_font-color-menuburger;
					@include media-breakpoint-up(md) {
						margin-top: 0;
					}
					font-size: 2rem;
				}
				.text{
					@include media-breakpoint-down(sm) {
					display: none;
				}
				text-align: right;
			    padding: 2rem 2rem 0;
			    a:link, a:visited{
			    	font-weight: 600;
			    	color:black;
			    }
			}
			}

		}

		//Menu
		.menuwrapper{
			position: relative;
			z-index: 1000;
			margin:0 -15px;

			.menurow{
				position: absolute;
				@include menu-r(12,12,6,4,3)

				@include pp(2,3,5);
				background-color: $tmpltt_layout_color-menu;
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
				}
				h4,p, a:link, a:visited{
					color: $tmpltt_font-color-menu;
				}

			}
			.menucloser{
				color: $tmpltt_font-color-menu;
				text-align: right;
			}
			.menucol{
				.title{
					display: none;
				}
			}

			.menuitems{
				li{
					padding-left: $spacer*2;
					font-size: 1.5rem;
					font-weight: 500;

					&.main_links, &.links_title{
						font-weight: 900;
						font-size: 1.8rem;
						padding-top: $spacer*2;
						color: $tmpltt_font-color-menu;
					}
				}
			}

			// button{
			// 	font-size: 2.5rem;
			// }
			// .menu {
			// 	background: $rot;
			// 	position: absolute;
			// 	z-index: 1000;
			// 	top:0;
			// 	left:0;
			//
			// }
		}
	}
}
