.ViewUeberUns{

	background-color: #fff;
	padding: 0 -2rem;

	.container{
		@include pp($spacer*2, $spacer*4);
	}

	.h1{
		color:$tmpltt_font-color-title;
		font-weight: 300;
		font-size: 3.8rem;
	}
	.text{
		@include make-r-col(12)
		padding: $spacer;
	}
	a:link, a:visited{
		color:$tmpltt_font-color-title
	}
}
