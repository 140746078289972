//move nav higher
.site-header.search-nav-top .SearchFormEnhanced{
	top: 58%;
}

.SearchFormEnhanced{

	position: absolute;
	top: 70%;
	@include media-breakpoint-up(md) {
		top: 80%;
	}
	z-index: 100;
	height: 40px;

	width: 100%;
	border-width:0;
	padding-right: 2rem;

	.tab-content{
		height: 40px;
	}

	.title{
		display: none;
	}
	legend{
		display: none;
	}

	#searchContent{ 
		background-color: white;
		padding-right: 0 2rem;
		width: 100%;
	}


	.fieldsets-main{
		@extend .d-flex;
		@extend .flex-wrap;
		@include make-row();

		.option-select{

			.os__control{
				border-color: transparent;
				border-radius: 0;
				box-shadow: none;
				width:100%;
				&:hover{
					border-color: transparent
				}
				min-height:36px;
				margin-bottom:4px;
				// padding-right: 10px;
			}

			.os__placeholder{
				color: $tmpltt_font-color-searchmask;
			}

			.os__indicators{
				order: -1;
			}

			.os__clear-indicator{
				order: 2;
				border: none;
			}

			.os__value-container{
				padding-left: 0;
			}

			.os__indicator{
				padding: 4px;
				border-left: 1px solid $tmpltt_font-color-searchmask;
			}

			.os__indicator-separator{
				display: none;
			}
		}


		.fs-search-keywords{
			@include make-r-col(12,12,6,6,7);

			.keywords{
				display: inline-block;
				width: 94%;
				@include media-breakpoint-only(xs) {
					width: 90%;
				}

				label{
					display: none;
				}
				input{
					border-radius:0;
					border-width:0px;
					padding: 5px 10px;
					height:3rem;
					margin: .5rem 1rem;
					margin-top: .5rem;
					margin-right: 1rem;
					background-color: #f2f2f2;
					width: 95%;
				}
				input::placeholder{
					color: $tmpltt_font-color-searchmask;
					font-weight: 200;
				}
			}

			.submit{
				display: inline-block;
				width: 3%;
				@include media-breakpoint-only(xs) {
					width: 10%;
				}
				.submit-label{
					display: none;
				}
				.submit-button{
					border: none;
					background: #fff;
					font-weight: 200;
					color:$tmpltt_font-color-searchmask;
					margin-right: $spacer;
					margin-bottom: 0;
					margin-top: .5rem;
					padding-left: 5px;
				}
			}

		}

		.fs-wrapper{
			height: 40px;
			max-width: 100%;
			@include make-r-col(12,12,6,6,5);
			display: flex;

			justify-content: space-evenly;
			@include media-breakpoint-up(md) {
				justify-content: center;
			}

			background: #fff;
			background-clip: content-box;
		}

		.fs-search-timespan{
			@include media-breakpoint-up(md) {
				border-left:1px solid $tmpltt_font-color-searchmask;
			}
			height: 28px;
			margin-top: 0.5rem;

			.fa{
				position: relative;
				top: -2px;
				color:$tmpltt_font-color-searchmask;
			}

			.preselect{
				display:none;
			}
		}

		.search-extend-controls{
			.extend-button{
				button{
					z-index:10;
					font-size: 1.4rem;
					color:$tmpltt_font-color-searchmask;
					margin-top: 0.5rem;
					height: 3rem;
				}
			}
		}

		.fs-search-town{
			// @include make-r-col(4,4,2);
			width: 150px;

			ul{
				list-style: none;
				padding: 0;
			}
			button:not(.dropdown-item){
				font-size: 1.4rem;
				background-color:transparent;
				border:none;
				margin-top: .5rem;
				color:$tmpltt_font-color-searchmask;
				border-left:1px solid $tmpltt_font-color-searchmask;

			}
			.dropdown-toggle::after{
				display:none;
			}
			.dropdown-toggle::before{
				@extend .fa;
				content:" \f078";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
		}

		.fs-search-extend-controls{
			// @include make-r-col(4,4,2);
			// background: yellow;

			.extend-button button{
				font-size: 1.4rem;
				border:none;
				margin-top: .5rem;
				color: $tmpltt_font-color-searchmask;
				border-left:1px solid $tmpltt_font-color-searchmask;

				margin-top: 4px;
				border-radius: 0rem;
			}

		 	button.btn.collapsed:before{
				@extend .fa;
				content:" \f078";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
 			button.btn:before{
				@extend .fa;
				content:" \f077";
				margin-right:.5em;
				display:inline;
				width:auto;
			}
		}
	}



	#fieldsets-extended{

		padding: $spacer $spacer*2;
		background: #fff;

		.option-select{
			// background: green;

			.os__control{
				// display: none;
				border-color: transparent;
				border-radius: 0;
				box-shadow: none;
				width:100%;
				background-color:rgba(163, 197, 200, 0.25);
				&:hover{
					border-color: transparent
				}
				min-height:36px;
				margin-bottom:4px;
			}

			.os__indicator{
				padding: 4px;
			}
		}

		.fieldset-verortung{
			display:none;
		}

		.search-location{

			.title{
				display: block;
			}


			label{display: none;}

			fieldset{
				margin: 0.5rem 1rem;
				padding: 1rem;
				background:rgba(155, 188, 191, 0.25);
			}

			.input-group{
				font-size: 1.3rem;
				font-weight: 400;

				.custom-select, input{
					font-size: 1.3rem;
					color: $tmpltt_font-color-input-placeholder;
					background-color: white;

					border-width: 0px;
					border-style:solid;
				}
				// input{padding:0 1rem; }
				input::placeholder{color:$tmpltt_font-color-input-placeholder;}
			}
			.input-location-center{

			}
			.select-search-distance{display: none;}
			.select-location-center{display: none;}
		}

		.search-constrict{
			margin-top:2rem;
			.form-check-label{
				@include pp(0,0,0,1);
			}
			.title{
				display: inline-block !important;
				position: inherit;
			}
		}

		.fieldset-datatype{
			display: none;
		}

		.detailsearch{
			@include mm(1,0)
			@include pp(1,3)
			font-size: 1.3rem;
			font-weight: 300;
			background-color: white;
			border-radius: 0;
			border:none;

			a{
				color: $tmpltt_font-color-results-linktext;
			}
			.expander{
				display:none;
				&:after{
					padding-left: 2rem;
					@extend .fa;
					//content:" \f077"
				}
			}
			.collapsed .expander{

				//&:after{content:" \f078"}
			}
		}

		.fieldset-controls{

			@include mm(3,0,0);

			display: inherit;

			button {
				background-color: #008AC2;
				font-size: 1.3rem;
				color:white;
				border-width: 0;
				border-radius: 0;
				@include pp(1,4);
				margin-bottom: 2rem;
				margin-top:1rem;
			}

			.button-reset{

				float:left;

			}
			.button-submit{

				display: block;

				color:white;
				font-size: 1.3rem;
				float:right;

			}

		}
	}

}
