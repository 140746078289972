//site specific overrides
body{
  // background: red !important;
  .cContainerHero{
    @include make-container-r;
  }

  .TemplateMain .site-header .site-opening .logocol .logo{
    margin-bottom: 0;
  }

  //FOOTER
  footer {
    .footer-row.partner{
      display: none;
    }
    .ruler{
      display: none;
    }
  }


  	.ViewUeberUns .ruler{
  		border-top: 2px solid $tmpltt_layout_color-footer-ruler;
      margin-top: 30px;
      padding-bottom: 30px;
  	}
}


.ViewThema{

  a{
    margin-bottom: 0;
  }

}

.ViewStatic{

  background-color: #fff;
  padding: 0 -2rem;

  .container{
    @include pp($spacer*2, $spacer*4);
  }

  a:link, a:visited{
    color: $tmpltt_font-color-highlight;
  }
}
