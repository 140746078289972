/*******************************************
	Loader
*******************************************/

#BusyAnimationSharePartial{
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
	pointer-events: none;

	.busyanimation_background{
		position: absolute;
		width: 100%;
		height: 100%;
		background: $tmplsh_loader_layout_color;
		opacity: 0.3;
	}

	&.active{
		opacity: 1;
		transition:opacity 500ms;
	}

	&.inactive{
		opacity: 0;
		transition:opacity 500ms;
	}
}
