.ViewThema{

	display: flex;

	a{
		color: $orange;
		
		display: block;
		text-align: center;
		margin-bottom: 2em;
	}

}