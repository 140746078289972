//@import "../../../ConfigScss";
@mixin debug-layout {
	// Check the guaranteed-existing variable. If it didn't exist
	  // before calling this mixin/function/whatever, this will
	  // return false.
	@if $debug-scss
	{
	 //https://doginthehat.com.au/2016/12/sass-mixin-to-debug-your-css-layout/
	 outline:1px double purple;
		> * {
				outline:1px solid green;
				> * {
						outline:1px dotted blue;
						> * {
								outline:1px dashed pink;
								> * {
										outline:1px double dashed black;
								}
						}
				}
		}
	}
}

@function map-get-or-key($map, $key) {
	@if map-has-key($map, $key) or map-has-key($map, -$key) {
		@if $key != 'auto' and type-of($key) == 'number' and $key < 0 {
			@return 0 - map-get($map, -$key);
		} @else {
			@return map-get($map, $key);
		}
	} @else if type-of($key) == 'string' {
		@return unquote($key);
	} @else {
		@return $key;
	}
}

@function bsize($key) {
	@return map-get-or-key($spacers, $key);
}

@mixin m($space) {
	//creates margin with the size of spacer
	margin: bsize($space);
}

@mixin inline-down($size, $margin:1rem){
	//display element inline with a right margin of $marin in all media sizes down from $size
	@include media-breakpoint-down($size) {
			display: inline-block;
			margin-right:$margin;
		}
}

//uses bootstraps build in mixins to create a container for a row
//max-widths for responsive views are applied
@mixin make-container-r(){
	@include make-container();
	@include make-container-max-widths();
}
//Setzt volle Bildschirmbreite
@mixin make-container-fluid(){
		@include make-container();
}

@mixin font-r-size($fxl, $flg:null, $fmd:null, $fsm:null, $fxs:null){
	// sets font size for the different screen sizes / media sizes from xl to xs
	// first argument used for all sizes required, subsequent arguments supersede fontsize for all respective smaller media sizes
	font-size:$fxl;
	@if $flg{
		@include media-breakpoint-down(lg) {
			font-size:($flg);
		}
	}
	@if $fmd{
		@include media-breakpoint-down(md) {
			font-size:($fmd);
		}
	}
	@if $fsm{
		@include media-breakpoint-down(sm) {
			font-size:($fmd);
		}
	}	
	@if $fxs{
		@include media-breakpoint-down(xs) {
			font-size:($fxs);
		}
	}
}

@mixin make-r-col($cxs, $csm:null, $cmd:null, $clg:null, $cxl:null){
	//make columns for different media sizes 
	// first argument used for all sizes required, subsequent arguments supersede fontsize for all respective LARGER media sizes
	@include make-col-ready();

	@include make-col($cxs);
	@if $csm{
		@include media-breakpoint-up(sm) {
			@include make-col($csm);
		}
	}
	@if $cmd{
		@include media-breakpoint-up(md) {
			@include make-col($cmd);
		}
	}
	@if $clg{
		@include media-breakpoint-up(lg) {
			@include make-col($clg);
		}
	}
	@if $cxl{
		@include media-breakpoint-up(xl) {
			@include make-col($cxl);
		}
	}
}

@mixin make-r-col-offset($cxs, $csm:null, $cmd:null, $clg:null, $cxl:null){
	// create different offsets for columns (moving to the right) for different media sizes
	@include make-col-offset($cxs);
	@if $csm{
		@include media-breakpoint-up(sm) {
			@include make-col-offset($csm);
		}
	}
	@if $cmd{
		@include media-breakpoint-up(md) {
			@include make-col-offset($cmd);
		}
	}
	@if $clg{	
		@include media-breakpoint-up(lg) {
			@include make-col-offset($clg);
		}
	}
	@if $cxl{
		@include media-breakpoint-up(xl) {
			@include make-col-offset($cxl);
		}
	}
}




@mixin menu-r($xs, $sm:null, $md:null, $lg:null, $xl:null ){
	//sets width of element for different media sizes, adjust left margin to move element to the right

	 	$pw: percentage($xs / $grid-columns);
	 	margin-left: calc(100% - #{$pw});
	 	width: $pw;
	
    @if $sm{
    	@include media-breakpoint-up(sm) {
    	$pw: percentage($sm / $grid-columns);
    	margin-left: calc(100% - #{$pw});
    	width: $pw;
    }
	}	

	 @if $md{
	 	@include media-breakpoint-up(md) {
    	$pw: percentage($md / $grid-columns);
    	margin-left: calc(100% - #{$pw});
    	width: $pw;
    }
	}	

	 @if $lg{
	 	@include media-breakpoint-up(lg) {
    	$pw: percentage($lg / $grid-columns);
    	margin-left: calc(100% - #{$pw});
    	width: $pw;
    }
	}	

	 @if $xl{
	 	@include media-breakpoint-up(xl) {
    	$pw: percentage($xl / $grid-columns);
    	margin-left: calc(100% - #{$pw});
    	width: $pw;
    }
	}	
	
	
}




@mixin mm($spacet:0, $spacer:null, $spaceb:null, $spacel:null) {
// set margin as multiple of $spacer (bootstrap) variable
// required first attribute sets margin on all sides, subsequent arguments supersede left+right, bottom, and left
// analog to margin: top, [right, bottom, left]
	margin-top: bsize($spacet);
	margin-right: bsize($spacet);
	margin-bottom: bsize($spacet);
	margin-left: bsize($spacet);

	@if $spacer{
		margin-right: bsize($spacer);
		margin-left: bsize($spacer);
	}
	
	@if $spaceb{
		margin-bottom: bsize($spaceb);
	}
	
	@if $spacel{
		margin-left: bsize($spacel);
	}
	
}

@mixin pp($spacet:0, $spacer:null, $spaceb:null, $spacel:null) {
// set padding as multiple of $spacer (bootstrap) variable
// required first attribute sets padding on all sides, subsequent arguments supersede left+right, bottom, and left

	
	padding-top: bsize($spacet);
	padding-right: bsize($spacet);
	padding-bottom: bsize($spacet);
	padding-left: bsize($spacet);

	@if $spacer{
		padding-right: bsize($spacer);
		padding-left: bsize($spacer);
	}
	
	@if $spaceb{
		padding-bottom: bsize($spaceb);
	}
	
	@if $spacel{
		padding-left: bsize($spacel);
	}
	
}


// various margin/padding mixins based on $spacer (bootstrap) variable for specific sides of the element
@mixin mt($space) {
	margin-top: bsize($space);
}

@mixin mb($space) {
	margin-bottom: bsize($space);
}

@mixin ml($space) {
	margin-left: bsize($space);
}

@mixin mr($space) {
	margin-right: bsize($space);
}

@mixin p($space) {
	padding: bsize($space);
}

@mixin pt($space) {
	padding-top: bsize($space);
}

@mixin pb($space) {
	padding-bottom: bsize($space);
}

@mixin pl($space) {
	padding-left: bsize($space);
}

@mixin pr($space) {
	padding-right: bsize($space);
}

@mixin px($space) {
	padding-right: bsize($space);
	padding-left: bsize($space);
}
@mixin py($space) {
	padding-top: bsize($space);
	padding-bottom: bsize($space);
}



@mixin mx($space) {
	@include ml($space);
	@include mr($space);
}

@mixin my($space) {
	@include mt($space);
	@include mb($space);
}

@mixin px($space) {
	@include pl($space);
	@include pr($space);
}

@mixin py($space) {
	@include pt($space);
	@include pb($space);
}


@mixin eventdivider(){
	border-style: solid;
	border-width:0 1px 0 0;
	line-height: 1em;
	@include pp(0, 3);
}

@mixin debug-r(){
	body:before{
		display: block;
		position: fixed;
		top: 0;
		z-index: 1000;
		
		height: 30px;
		line-height: 30px;
		width: 100%;
		text-align: center;
		font-weight: bold;
		
		@include media-breakpoint-only(xs) {
			content: 'XS';
			background: lime;
		}
		@include media-breakpoint-only(sm) {
			content: 'SM';
			background: yellow;
		}
		@include media-breakpoint-only(md) {
			content: 'MD';
			background: aquamarine;
		}
		@include media-breakpoint-only(lg) {
			content: 'LG';
			background: khaki;
		}
		@include media-breakpoint-only(xl) {
			content: 'XL';
			background: orange;
		}
	}
}

@mixin word-wrap() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@mixin word-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin word-manual() {
	-webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
}
