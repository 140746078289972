
/*******************************************
	MessageSharePartial
*******************************************/
.MessageSharePartial{
	background: $tmpltsh_layout_color_message;
	color: $tmpltsh_font_color_message;
	margin:6rem auto;
	align-self: flex-start;
}
