.SearchTopic, .SearchAudience, .SearchProvidersHierarchy{

	color: $tmpltt_font-color-results;
		margin-bottom: $spacer*2;

	.wrapper{
		background: #fff;
		padding: $grid-gutter-width;
		padding-bottom: $spacer *3;
		height: 100%;
		box-shadow: $tmpltt_shadow;
	}

	h2{
		font-size: 2.4rem;
	}

		.content{
				color: $tmpltt_font-color-results_body_text;
	}

	.link{
		color: $tmpltt_font-color-results-linktext;
		display: block;
		font-size: 1.3rem;
		margin-bottom: 5px;
		cursor: pointer;

		&:hover{
			text-decoration: underline;
		}
	}
}

.TopicAndAudience.block{

	.wrapper{
		background-color: $tmpltt_layout_color_kachel;
	}
	.SearchTopic{
		@include make-r-col(12, 12, 12, 9);

		.content{
			@include make-r-col(12, 12, 12, 4);
			}
		.topics{
			hyphens: auto;
			@include make-r-col(12, 12, 12, 8);
			@include media-breakpoint-up(sm) {
				columns: 2;
			}
		}
	}

	.SearchAudience{
		@include make-r-col(12, 12, 12, 3);

		.audience{
			hyphens: auto;
			@include media-breakpoint-up(sm) {
				columns: 2;
			}
			@include media-breakpoint-up(lg) {
				columns: 1;
			}
		}

	}
}

.TopicAndAudience.sidebar{
	@include make-r-col(12, 12, 12, 3);

	@include media-breakpoint-only(md) {
		// margin-top: $grid-gutter-width;
	}
	.wrapper{
		background-color: $tmpltt_layout_color_kachel;
	}
	.SearchTopic{
		@include make-r-col(12);
		.content{
			@include make-r-col(12);
		}
		.topics{
			@include make-r-col(12);
			hyphens: auto;
			@include media-breakpoint-up(sm) {
				columns: 2;
			}
			@include media-breakpoint-up(lg) {
				columns: auto;
			}
		}
	}

	.SearchAudience{
		@include make-r-col(12);
		.audience{
			hyphens: auto;
			@include media-breakpoint-up(sm) {
				columns: 2;
			}
			@include media-breakpoint-up(lg) {
				columns: auto;
			}
		}
	}

	.SearchProvidersHierarchy{
		@include make-r-col(12);

		.providerHierarchy{
			hyphens: auto;
			@include media-breakpoint-up(sm) {
				columns: 2;
			}
			@include media-breakpoint-up(lg) {
				columns: auto;
			}

			ul.level_0{
					list-style-type: none;
					font-weight: bold;
				}
			ul{
				 list-style-type: none;
				 font-weight: normal;
			}
			ul ul li span:before {
			  content: '- ';
			}
		}
	}
}

//*********************
// 	Font Resize
//*********************

.SearchTopic{
	&.fs-plus-1{
		.content{
			font-size: 1.6rem;
		}
		.topics .link{
			font-size: 1.6rem;
		}
	}
	&.fs-plus-2{
		.content{
			font-size: 1.7rem;
		}
		.topics .link{
			font-size: 1.7rem;
		}
	}
	&.fs-plus-3{
		.content{
			font-size: 1.8rem;
		}
		.topics .link{
			font-size: 1.8rem;
		}
	}
}

.SearchAudience{
	&.fs-plus-1{
		.content{
			font-size: 1.6rem;
		}
		.audience .link{
			font-size: 1.6rem;
		}
	}
	&.fs-plus-2{
		.content{
			font-size: 1.7rem;
		}
		.audience .link{
			font-size: 1.7rem;
		}
	}
	&.fs-plus-3{
		.content{
			font-size: 1.8rem;
		}
		.audience .link{
			font-size: 1.8rem;
		}
	}
}

.SearchProvidersHierarchy{
	&.fs-plus-1{
		.link{
			font-size: 1.6rem !important;
		}
	}
	&.fs-plus-2{
		.link{
			font-size: 1.7rem !important;
		}
	}
	&.fs-plus-3{
		.link{
			font-size: 1.8rem !important;
		}
	}
}
