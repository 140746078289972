/*******************************************
     Footer
*******************************************/

footer{
	@include make-container-r();

	.footer-row{
		//create row without gutter
		@include make-row(0);

		.wrapper{
			@include pp(4);
			padding-top: 3rem;
			
			@include media-breakpoint-up(sm) {
				display: flex;
			}
		}
	}
	
	h3{
		font-size:1.3rem;
		white-space: nowrap;
	}

	section{
		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}

	.partner{
		.kommunen{
			background: $tmpltt_layout_color-footer-kommunen;
			@include make-r-col(12, 12, 7);
		}
		.freistaat{
			background: $tmpltt_layout_color-footer-freistaat;
			@include make-r-col(12, 12, 5);
		}
	}

 	.gebit{

		padding: 2*$spacer 2.5rem;
		background:#fff;

 		p{
 			max-width: 500px;
 			color: $tmpltt_font-color-content;
 			font-weight: 300;
 			font-size: 1.2rem; 
 		}

		@include media-breakpoint-up(sm) {
	 		img{
	 			@include mm(0, 5);
	 		}
	 		p{
	 			@include mm(0, 5);
	 		}
		}
 	}

	.ruler{
		border-top: 1px solid $tmpltt_layout_color-footer-ruler;
	}

	.section-legalcopy{
		display: block;
		padding:2rem 2rem 1rem;
		.title{
			display: none;
		}
		text-align: center;
		font-size: 1.2rem;
		font-weight: 300;
		background: $tmpltt_layout_color-footer;
		color:white;
		a{color:white;}
	}

	//TMP: bitte beachten, kann überarbeitet werden, nur Temporär wegen DH eingefügt
	.kommunen{
		.img-fluid{
			max-height: 90px;
			@include pl(20px);
		}
	}
}
