/*******************************************
	Fonts
*******************************************/

%font{
	font-family: $tmpltt_font-family-general;
	color: $tmpltt_font-color-content;
	font-weight: 400;
	line-height: $tmpltt_lineheight;
}

html, body {
	//set base font size
	font-size: 60%;
}


%header{
	@extend %font;
}

%font-menu{
	@extend %font;
}


/*******************************************
	Structure
*******************************************/

html, body{
	height: 100%;
}

body{
	@extend %font;
	background-color: $tmpltt_layout_color-general;
	font-size: 1.5rem;
}
#site_wrapper{
	margin:0 auto;
	max-width:1240px;
}
.mainblock{
	padding:1rem;
}

//make sure $grid-gutter-width is set
.container{
	@include make-container-r();
}
.row{
	@include make-row();
}

main{
	@include make-container-r();
	padding-top: 15px;
}

/*******************************************
	Tags
*******************************************/
h1, h2, h3, h4, h5, h6{
	@extend %header;
	color: $tmpltt_font-color-title;
	font-weight:500;
	line-height:$tmpltt_lineheight;
}
h1,h2{
	font-weight:700;
}



/*******************************************
	Misc
*******************************************/

//remove firefox outlines
a:focus{
	outline: none;
}


/*******************************************
	Allgemein
*******************************************/
.nopadding {
	padding: 0 !important;
	margin: 0 !important;
}

.accessibility-icons{
	color:#ccc;
	font-size:2rem;
	.col-auto{
		padding-right:10px;
		padding-left: 10px;
	}
}

.vorhanden {
	color:$tmpltt_font-color-title;
}

.main-row {
	@include mx(3);
	@include make-row();
}

button.backBtn{
	background: none;
	border: none;
	color: #fff;
}


/**********************************
	SEARCH
***********************************/

.search-extend-controls button.btn.collapsed:before{
	@extend .fa;
	content:" \f078";
	margin-right:.5em;
	display:inline;
	width:auto;
}
.search-extend-controls button.btn:before{
	@extend .fa;
	content:" \f077";
	margin-right:.5em;
	display:inline;
	width:auto;
}


/******* TEST AREA ******/
.cProviderOfferSpezial{
    color:         $tmplt_font-color-results_spezialprovideroffer;
    font-size:     0.8em;
}

/***** Scrollbar ******/
//react https://css-tricks.com/the-current-state-of-styling-scrollbars/
//https://www.digitalocean.com/community/tutorials/css-scrollbars
//Scrollbars in Chrome/Edge/Safari
* {
  scrollbar-width: thin;
  //scrollbar-color: blue orange;
}
*::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
*::-webkit-scrollbar-track {
  background: orange;        /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;  /* creates padding around scroll thumb */
}
