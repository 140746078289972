//*********************************
// Font import
//*********************************
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500,700,900&display=swap');

//*********************************
//FAM-GIFHORN
//Grundfarben
//*********************************

$white:    	    #fff;
$black:         #000;
$gray: 			   	#999;
$gray_dark:     #aaa;

//Grundfarben
$fgif_lila: rgb(0, 3, 107);
$fgif_blau: #274891;//rgb(0, 0, 255);
$fgif_gold: rgb(186, 161, 94);
$fgif_rot: rgb(242, 87, 61);

$fgif_lila_transparenz80: rgba(0, 3, 107, 0.8);
$fgif_lila_transparenz60: rgba(0, 3, 107, 0.6);
$fgif_blau_transparenz80: rgba(0, 0, 255, 0.8);
$fgif_blau_transparenz60: rgba(0, 0, 255, 0.6);
$fgif_blau_transparenz40: rgba(0, 0, 255, 0.4);
$fgif_blau_transparenz20: rgba(0, 0, 255, 0.2);
$fgif_gold_transparenz20: rgba(186, 161, 94, 0.2);

$layout_bg: #f4f5f9;

//Zeilenhöhe
$lineheight:		   1.6;

//overwrite bootstrap gutter width
$grid-gutter-width: 20px; //ToDo scss: soll es eine Template-Variable sein?

//*********************************
//Variablen für Template-Thueringen [tmpltt]
//Marker -ToDo scss:-
//*********************************

//Schatten ToDo scss: was st das?
$tmpltt_shadow: 1px 3px 5px #ccc;

//Farbkonzept SuchErgebnisse -> Text und Boxen -> SearchCardTitel
$tmpltt_font-color-results:   $fgif_blau;
$tmpltt_font-color-results-linktext: $fgif_blau;
$tmpltt_font-color-results_contrast: $white;

//Detailansicht Schriftfarben
//Überschriften (Header, Themensuche, Zielgruppensuche, Detailansicht Überschriften H1)
$tmpltt_font-color-highlight:   $fgif_blau;
//Detailansichten Hintergrundfarbe je gerade Zeile
$tmpltt_font-color-highlight-light: $layout_bg;

//Hintergrundfarbe
$tmpltt_layout_color-general: $layout_bg;
//Hintergrunffarbe Kacheln
$tmpltt_layout_color_kachel:  $white;

//Footer Boxfarbe
$tmpltt_layout_color-partial: $white;

//Menüfarbe
$tmpltt_layout_color-menu:		$fgif_rot;

//BoxfarbeHintergrund Hinweistexte (z.B. keine Ergebnisse gefunden)
$tmpltt_layout_color-message: $tmpltt_layout_color-menu;

//Letzter Footer Boxfarbe (copytight, Impressum,...)
$tmpltt_layout_color-footer: $fgif_blau;

//Footer linke Boxfarbe (Teilnehmende Kommunen...)
$tmpltt_layout_color-footer-kommunen: $tmpltt_layout_color-partial;

//Footer Rechte Boxfarbe(gefördert von)
$tmpltt_layout_color-footer-freistaat: $tmpltt_layout_color-partial;

//Footer Trennlinie
$tmpltt_layout_color-footer-ruler: $fgif_blau;


//Header (zoom, druck, usw.)
$tmpltt_font-color-menufunctions:     $fgif_blau;
//Header (burgermenu)
$tmpltt_font-color-menuburger:        $fgif_blau;
//Schriftfarben Inhalte und Menüs
$tmpltt_font-color-menu:              $white;
$tmpltt_font-color-title:             $fgif_blau;
$tmpltt_font-color-content:           $black;
$tmpltt_font-color-content-h2-title:  $fgif_blau;
$tmpltt_font-color-input-placeholder: $gray;
$tmpltt_font-color-content-versus:    $tmpltt_font-color-menu;

//SchriftArt
$tmpltt_font-family-general:    'Source Sans Pro', sans-serif;//'Libre Franklin', sans-serif;

//Schrift und Icon Farbe Suchleiste, lieber für alle standard grau lassen
$tmpltt_font-color-searchmask:   $gray_dark;

//Zeilenhöhe
$tmpltt_lineheight: $lineheight;

//Schriftfarbe Topic and Audince (Bodytext ansteuern)
$tmpltt_font-color-results_body_text: $fgif_blau;

//Schriftfarbe für Angebote in Kachelresults, die zu den 'besonderen' Organisationen gehören, z.B. FGIF-Landkreis Hierarchie
$tmplt_font-color-results_spezialprovideroffer: $fgif_rot;

//*********************************
//Variablen für beide Templates [tmpltsh]
//Marker -ToDo scss:-
//*********************************

//Ladesymbol Farbe
$tmplsh_loader_color: $fgif_rot;

//Seitenhintergrund Overlay Farbe
$tmplsh_loader_layout_color: $black ;

//Größe
$tmplsh_loader_size: 200;
//the :export directive is the magic sauce for webpack
:export {
  loader_color: $tmplsh_loader_color;
  loader_size: $tmplsh_loader_size;
  iconsSvg_color: $tmpltt_font-color-results;
  iconSvgNeu_color: $fgif_rot;
}

//Hinweistexte & Farbboxen
$tmpltsh_layout_color_message: $tmpltt_layout_color-message;
$tmpltsh_font_color_message: $tmpltt_font-color-content-versus;

//Preview vor Backend
$tmpltsh_font-color-watermark: rgba(0, 0, 0, 0.2);


//*********************************
// entry point for all basis scss logic
//*********************************
@import './basis/basis_t/scss/App.scss';

//*********************************
// site specific overrides
//*********************************
@import './sites/fgifhorn_t/scss/Site.scss';

//*********************************
// Debug
//*********************************

// Set the variable to false only if it's not already set.
$debug-scss: false !default; //true; //

// Show cureent responsive viewport
// @include debug-r();
