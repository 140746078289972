/*******************************************
 Searchresults
*******************************************/



.react-daterange-picker{
	.react-daterange-picker__wrapper{
		border: none;
	}
	.react-daterange-picker__inputGroup{
		display: none;
	}
	.react-daterange-picker__range-divider{
		display: none;
	}
	.react-daterange-picker__clear-button{
		display: none;
	}
	.react-daterange-picker__calendar-button{
		position: relative;
		top: -3px;
	}
}

.ViewResults .MessageSharePartial{
	@include media-breakpoint-down(md) {
		order: -1;
	}
}

.SearchResults{

	@include make-r-col(12, 12, 12, 9);

	//put search results on top
	@include media-breakpoint-down(md) {
		order: -1;
		margin-bottom: $grid-gutter-width;
	}
	position: relative;

	@include media-breakpoint-down(sm) {
		top: -100px;
	}

	.searchresults-header{

		@include media-breakpoint-up(md) {
			position: absolute;
			top: -200px;

			width: calc(100% - 19px);
		}

		background-color: #fff;
		@include pp(3, 4);
		color: $tmpltt_font-color-results;

		a, button{
			color: $tmpltt_font-color-results;
		}

		.title{
			font-size: 2.8rem;
		}
		.desc{
			display: none;
		}
		.active_filters{
			max-height: 100px;
			overflow: auto;

			span{
				&::before{
					content:"• ";
				}
				display: inline-block;
				margin: 0 0.75rem;
			}
		}
		button{
			padding: 0;
		}

		.resetBtn{
			background: none;
			border: none;
			color: $tmpltt_font-color-results;

			margin-left: $spacer;
			padding-left: $spacer;
			border-left: 1px solid $tmpltt_font-color-results;
		}
	}

	//center MessageSharePartial
	.MessageSharePartial{

		@include media-breakpoint-down(sm) {
			margin-left: 30px;
			margin-right: 30px;
		}

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 80px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 70%;
		}
	}

	.pagination-wrapper{
		background: $tmpltt_font-color-results;
		@include pp(2,0);
		h3{
			color: $tmpltt_font-color-results_contrast;
			text-align: center;
			font-size: 1.4rem;
			padding-top: 8px;
		}

		.pagination-header{
			position: relative;
			margin-bottom: $spacer * 2;
		}

		.pagination-select{
			position: absolute;
			top: 0px;
			right: 2px;
			color: $tmpltt_font-color-results_contrast;
			background: $tmpltt_font-color-results;
			display: block;
			width: 65px;
			padding: 0;
			font-size: 1.4rem;
			line-height: 1.4rem;

			.ps__control{
				color: $tmpltt_font-color-results_contrast;
				background: $tmpltt_font-color-results;
				border: none;
				text-align: right;
			}

			.ps__menu{
				color: $tmpltt_font-color-results;
			}

			.ps__single-value{
				color: $tmpltt_font-color-results_contrast;
			}

			.ps__indicator-separator{
				display: none;
			}

			.ps__indicator{
				color: $tmpltt_font-color-results_contrast;
			}
		}
	}

	.pagination{

		@include make-r-col(12);
		@include pp(0, 2);
		background-color: $tmpltt_font-color-results;
		border-radius: 0;


		a{
			font-size: 1.4rem;
			color:white;
			text-decoration: none;
			padding:0 1rem;
			font-weight: 300;
		}
		.disabled{
			a{
				color:#ccc;
				cursor:default;
			}
		}
		li{
			text-align:center;
			flex-basis: 0;
			flex-grow: 0;
		}
		li.active a{
			font-weight: 900;
			padding:0 1rem;
		}
		:not(.disabled) a:hover{
			font-weight: 700;
			padding:0 1rem;
		}
		.pageFirst, .pageLast, .pagePrev, .pageNext{
			a{
				font-size: 2rem;
				line-height: 2rem;
			}
		}
		.pagePrev{
			flex-grow:1;
			text-align: left;
		}
		.pageNext{
			flex-grow:1;
			text-align: right;
		}
	}

	.tag, .monat, .jahr{
		white-space: nowrap;
	}
	.tileview{
		.jahr{
			display: block;
		}
	}

	.SearchResultsWithMap{

		margin-top: $grid-gutter-width;

		&.row{
			// margin: 0;
		}

		.result-sets{
			@include make-r-col(12,12,6);
			border-width: 2px 0;
			position: static;
			min-height: 200px;
		}

		.MapResult{
			@include make-r-col(12,12,6);

			max-height: 100vh;
			// margin-bottom: 15px;
			position: relative;

			@include media-breakpoint-down(sm) {
				display: none;
			}

			.title{
				text-align: center;
				font-size: 1.3rem;
				padding: 1rem;
			}

			.mapviewer{
				width: 100%;
				height: 100%;
				padding: $spacer;
				background: #fff;
			}

			#map-results{
				// height: 50%;
				height: calc(100% - 5rem);
			}

			&.sticky{
				position: sticky;
				top: 0;
			}
		}
	}
}
