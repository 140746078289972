.cFfoContextGmapPartial{
  background: white;
  @include pp(10px, null, 20px, null);//padding: 10px; 20px freien Platz unter der Gmap Karte für die Benutzermeldung freilassen 
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    order: -1;
  }
}

.FfoGmapPart{}


.cFfoContextGmapPartial .ffoGmapMenuContainerRow{
  @include make-row();

  .ffoGmapMenuCol{
    @include make-r-col(12,6,6,8,8);
    @include pb(3);
    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      display: flex;
      //flex-direction: row;
      flex-wrap: wrap;

      li{
        //put search results on top
      	@include media-breakpoint-down(md) {
      		width: 100%;
      	}
        width: 50%; //zweispaltig
        label{
          padding-left: 5px;
          fontSize:"0.8em";
          hyphens: auto;
        }
      }
    }
  }

  .ffoGmapFilterCol{
    @include make-r-col(12,6,6,4,4);
    @include media-breakpoint-down(xs) {
      order: -1;
    }
  }
}


.cFfoContextGmapPartial .ffoGmapMenuContainerRow.cWithoutRegionFilter{
  @include make-row();

  .ffoGmapMenuCol{
    @include make-r-col(12);
    @include pb(3);
    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      display: flex;
      //flex-direction: row;
      flex-wrap: wrap;

      li{
        //put search results on top
          @include media-breakpoint-down(md) {
              width: 100%;
          }
        width: 33%; //dreispaltig
        label{
          padding-left: 5px;
          fontSize:"0.8em";
          hyphens: auto;
        }
      }
    }
  }
}
