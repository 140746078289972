.SearchCardsWrapper{

	header{
		display: none;
	}

	@include pp(3, 0);

	.search-cards-row{
		@include make-row();

		.SearchCard{
			&:not(.column-3){
				//default 4 cards of row
				@include make-r-col(12,6,4,4,3);
			}
			&.column-3{
				//3 cards of row, for example ViewThemaFfo
				@include make-r-col(12,6,6,4,4);
			}
		}
	}
}

.SearchCard{
	cursor: pointer;
	display: flex;
	@include mb($grid-gutter-width);
	.card{

		background-color: $tmpltt_layout_color_kachel;
		border:none;
		border-radius: 0;
		height: 100%;
		@include pp(0,0,3,0);
		box-shadow: $tmpltt_shadow;

		.card-body{
			flex-grow:0;
			@include pp(0);
			text-align: center;
			hyphens: auto;
			.card-title{
				hyphens: none;
				font-size: 2.3rem;
				color:$tmpltt_font-color-results;
				@include pp(3,3,0,3);
			}
			.decorationimage{

				background: gray;
				height:200px;

			}
			.card-text{
				color: $tmpltt_font-color-content;
				@include pp(0,0,5,0);
				line-height: 1.4;
			}
			&.description{
				flex-grow:1;
				@include pp(0,3,3,3);
			}
			&.more-bar{
				.card-link{
					@include pp(3,3);
					color:$tmpltt_font-color-results-linktext;
					border:1px solid $tmpltt_font-color-results;
					border-width: 1px 0 0;
				}
				text-align: center;
				.dropdown-toggle{
					@include pp(3,0,0,0);
					color:$tmpltt_font-color-results;
					border:1px solid $tmpltt_font-color-results;
					border-width: 1px 0 0;
					// height:20px;
				    margin: -30px -70px;
				    position:relative;
				    top:50%;
				    left:50%;
				}
			}
		}
	}

	a:hover{
		text-decoration: none;
	}

	&.no-link{
		cursor: auto;
		.more-bar{
			display: none;
		}
	}

	// ToDo: kann endgültig raus?, da SearchCardFilter nirgendswo benuzuz wird
	// &.SearchCardFilter{
	// 	cursor: auto;
	// 	.search_link{
	// 		text-decoration: underline;
	// 		cursor: pointer;
	// 	}
	// }
	.nav-link{
		border:none;
		background-color: #fffffe;
		color:$orange;
	}
	.nav-link:focus{outline: none;}
	.dropdown-menu{
		color:$orange;
		font-size: 1.3rem;
		box-shadow: 2px 2px 5px rgba(0,0,0,.3);
	}

	//font-resize
	&.fs-plus-1{
		.card-text{
			font-size: 1.6rem;
		}
	}
	&.fs-plus-2{
		.card-text{
			font-size: 1.7rem;
		}
	}
	&.fs-plus-3{
		.card-text{
			font-size: 1.8rem;
		}
	}
}
