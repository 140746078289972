
// common.DropDown

.filterdropdown.show >.nav-link{
  //border-radius: 1rem 1rem 0 0;
}
.dropdown-menu.show {
  display: block;
  margin: -1px;
  //margin-top: 4px;
  border-top: 0px;
  //background-color: //$white;
  color: $black;
  //background: linear-gradient(180deg, #eee, #fff);
}
.dropdown-item{
  border-radius: 0;
  background-color: transparent;
  //weil border-left vom Button props übernommen wird, hier ausschalten
  border: none !important;
  color: $black;
  font-size: 1.4rem;
  &:hover{
    background-color: #f2f2f2;
    color: $black;
  }
}
.dropdown-item.active, .dropdown-item:active{
  //background-color: transparent;
  background-color: $white;
  color: $black;
}
.dropdown-item.active:hover::before{
    content:"\f00d  ";
    font-family: 'Font Awesome 5 Free';
    font-weight:600;
  }
.dropdown-item.active::before, .dropdown-item:active::before{
  content:"\f00c  ";
  font-family: 'Font Awesome 5 Free';
  font-weight:600;
}
