/*******************************************
	Angebot
*******************************************/

.SearchDetails{

	@include make-r-col(12,12,12,9);

	@include media-breakpoint-down(md) {
		order: -1;
	}

	margin-top: -100px;
	@include media-breakpoint-up(md) {
		margin-top: -200px;
	}
	margin-bottom: $grid-gutter-width;

	.wrapper{
		background-color: #fff;
		padding: $spacer * 2;
	}

	.eventinfo{
		// width: 100%;

		.cNewDataset{
			height: 40px;
			float: right;
		}

		.eventdate{
			color: $tmpltt_font-color-results;
			&.datevonbis{
				font-size:1.6rem;
				font-weight: 600;
			}
			&.timevonbis{
				font-size: 1.4rem;
			}
			//siehe Menden SearchDetail.css
			// &.datebegin{
			// 	font-size:1.6rem;
			// 	font-weight: 600;
			// 	//@include inline-down(md)
			// }
			// &.dateend{
			// 	font-weight: 600;
			// 	margin-bottom: 1rem;
			// 	&:before{content:"bis "};
			// 	//@include inline-down(md)
			// }
			// &.timebegin{
			// 	font-size: 1.8rem;
			// 	//@include inline-down(md)
			// 	p:after{content:" Uhr";}
			// 	p{margin:0}
			// }
			// &.timeend{
			// 	font-size: 1.8rem;
			// 	//@include inline-down(md)
			// 	p:after{content:" Uhr";}
			// }
			// &.timebegin span, &.timeend span{
			// 	font-size: 1.5rem;
			// 	display: inline-block;
			// 	width: 2.7rem;
			// }
		}

		.offer-name-col{
			@include make-r-col(12,12,12,5);
			@include pr(3);
			display: flex;
			flex-direction: column;
			//Angebotskurzname wird direkt nach dem Datum angezeigt, default:
			//align-items:flex-start;
			//stellt das Datum oben und Kurzname mit der Organisationsname unten
			//justify-content: space-between;
		}

		.offer-name{
			hyphens: manual;
			width: 100%;
			//Silbenn-/Worttrennug automatisch ausführen
			@include word-wrap();
		}

		.summary {
			@include make-r-col(12,12,12,7);
			// @include pp(3,0,0,4);
			width: 100%;
		}

		.summary-title{
			color: $tmpltt_font-color-content;
			@include pt(2*$spacer);
			@include pb(3);
			font-weight: 700;
		}
		.summary-title-provider{
			color: $tmpltt_font-color-content;
			@include pt(1.5*$spacer);
			@include pb(1.5*$spacer);
			font-weight: 700;
		}


				.summery-label{
					width: 45%;
					font-family: $tmpltt_font-family-general;
					font-weight: bold;
					font-size: 0.9em;
				}
				.summery-value{
					font-family: $tmpltt_font-family-general;
					font-size: 1em;
				}
				table tr {
					//line-height: 18px;
				}
				table td {
					line-height: 15px;
					vertical-align: top;
				}

		.accessibilty-icons{
			@include mt(3);
			img, svg{
				height: 30px;
				width: auto;
				padding: 0 5px;
			}
			.icon_rollstuhl{
				border-right: 2px dotted $tmpltt_font-color-results;
				padding-left: 0;
				padding-right: 10px;
				margin-right: 5px;
			}

		}
	}

	a:link, a:visited{
		color:$tmpltt_font-color-highlight;
	}
	.link{
			color: $tmpltt_font-color-results;
			display: block;
			font-size: 1.3rem;
			margin-bottom: 5px;
			cursor: pointer;

			&:hover{
				text-decoration: underline;
			}
		}

	.title{
		color:$tmpltt_font-color-highlight;
	    font-weight: 600;
		font-size: 1.8rem;
	}


	.merkmal-dokument svg{margin-right: 1rem}

	.merkmal-label-veranstaltungstage .body p{
		max-height: 200px;
		overflow: auto;
	}

	.groups{
		padding: 0 $spacer;
	}

	.group{
		background: white;
		padding-top:1rem;
		margin:4rem 0;
		border-width: 2px 0 0 0;
		color: $tmpltt_font-color-content;

		//Eine Trennlinie zwieschen den Gruppen anzeigen
		border-bottom: 1px solid $tmpltt_font-color-results;
		padding-bottom: 5rem;

    .label h4{
    	color: $tmpltt_font-color-content;
    	font-weight: 700;
    	hyphens:auto;
    }

    .body {
			@include word-wrap();
		}

		>.title{
			//Gruppenbezeichnung ausblenden
			display: none;

			padding:$spacer;
			padding-left: 0;
		}
		&.inhalt > .title,
		&.zielgrupppe > .title{
			display:none;
		}
		&.kontaktkarte >{
			.title {
				display:none;
			}
			.col-12{
				padding:0;
			}
		}
		&.downloads .merkmal {
			.title{
				width:auto;
			}
			.value{
				p,ul{
					width:auto;
					margin-left:auto;
					margin-right:3rem;
				}
			}
		}
		&.beschreibung{
			&.col-12, {
				&.col-md-7{
					margin-left:0;
				}
			}
		}
	}

	.group-allgemeine-angaben-zum-angebot{}

	header{
		.title{
			font-size:3rem;
		}
		.subtitle{
			font-size:1.5rem;
			font-weight:600;
			color:$tmpltt_font-color-highlight;

		}
	}

	ul{
		margin:0;
			padding: 0;
			text-indent: 0;
			list-style-type: none;
	}

	.merkmal{

		// padding:1rem 0;

		.merkmal-col{
			@include pt(3);
			@include pb(2);
		}

		.merkmal-col.label{
			@include make-r-col(12,12,5);
		}

		.merkmal-col.body{
			@include make-r-col(12,12,7);
		}

		&:nth-of-type(odd){
			background: white;
		}
		&:nth-of-type(even){
			background:$tmpltt_font-color-highlight-light;
			//-ToDo scss:- Hier müssen noch Variablen für die Schriftfarben hinterlegt werden Ebene basis_t)
			}

		.title{
			font-size:1.5rem;
			color:#000;
		}
		.value {
			li{
				font-size:1.5rem;
				margin:0;
			}
			p,ul{
				font-size:1.5rem;
				list-style: none;
				padding: 0;
			}
		}

		&.bilder{background:transparent;}
		&.zielsetzung{background:$tmpltt_font-color-highlight-light;}
		&.download{background:$tmpltt_font-color-highlight-light;}

		&.kurzbeschreibung{

			.title{
				&.col-md-5{
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}

	.gallery {
		margin-bottom:2rem;
		img{
			width:100%;
		}
	}
	img.mapaddress{

		width:100%;
		height:auto;

	}

	section.maps{
	}

	//Font Resize
	&.fs-plus-1{
		.eventdate, .summary, .summary h4{
			font-size: 1.6rem !important;
		}
		.subtitle{
			font-size: 1.4rem !important;
		}
		.subtitle .link{
			font-size: 1.4rem !important;
		}
		.merkmal{
			font-size: 1.6rem !important;
		}
		.merkmal h4{
			font-size: 1.6rem !important;
		}
		.group h3{
			font-size: 1.8rem !important;
		}
	}
	&.fs-plus-2{
		.eventdate, .summary, .summary h4{
			font-size: 1.7rem !important;
		}
		.subtitle{
			font-size: 1.5rem !important;
		}
		.subtitle .link{
			font-size: 1.5rem !important;
		}
		.merkmal{
			font-size: 1.7rem !important;
		}
		.merkmal h4{
			font-size: 1.7rem !important;
		}
		.group h3{
			font-size: 1.9rem !important;
		}
	}
	&.fs-plus-3{
		.eventdate, .summary, .summary h4{
			font-size: 1.8rem !important;
		}
		.subtitle{
			font-size: 1.6rem !important;
		}
		.subtitle .link{
			font-size: 1.6rem !important;
		}
		.merkmal{
			font-size: 1.8rem !important;
		}
		.merkmal h4{
			font-size: 1.8rem !important;
		}
		.group h3{
			font-size: 2.0rem !important;
		}
	}

}
