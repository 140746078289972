.ViewKontakt{

	background-color: #fff;
	padding: 0 -2rem;

	.container{
		// top right bottom left
		@include pp($spacer*2, $spacer*4, $spacer*20, $spacer*4);
	}
	.title{
		color:white;
		font-weight: 300;
		font-size: 3.8rem;
		margin-bottom: 3rem

	}
	form{
		@include make-row();
		margin: 0;
	}
	label{
		@include make-r-col(12,12,3,2,2);
		margin-top: 1.5rem;
	}
	input, select, textarea{
		@include make-r-col(12,12,9,10,10);
		background-color:$tmpltt_layout_color-general;
		border-width: 0;
		//border-radius: 2rem;
		margin-top: .5rem;
		padding:.25rem 1rem;
		font-size:1.5rem;
		color:$tmpltt_font-color-input-placeholder;
	}
	.has-error{
		background-image: linear-gradient(to right, white 90%, $red);
	}
	h4 {
	  font-weight: bold;
	}
	textarea{
		height:1.5rem *12;
	}
	select{
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border:none;
		border-radius: 0;
	}
	.captcha{
		@include make-col(5);
		height:100px;
		background-color: gray;
		border-radius: 2rem;
		@extend .offset-md-2;
		margin-top: .5rem;

	}
	#submit{
		@include make-col(3);
		@extend .offset-md-2;
		margin-top: 1.5rem;
		background-color: $tmpltt_font-color-highlight;
		color:white;
		max-width: none;
	}
	#submit.send-false{
		opacity:.5;
	}

	.kontakttext{
		@include make-col(8);
		@extend .offset-md-2;
		padding: 5rem 0 16rem;
		a:link, a:visited{color:$orange}
	}
}
