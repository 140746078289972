.FontResizer{
	//Abstand zwischen Resize-GruppenButttons und weiteren einzelnen Buttons in der Reihe
	margin-right: 1rem;
	button{
		border: 1px solid $tmpltt_font-color-searchmask !important;
		border-radius: 50%;
		margin: $spacer 0.5*$spacer !important;
	}
	button[disabled]{
		opacity: 0.3;
	}

}
